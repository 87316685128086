import React, { useState, useEffect } from "react";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { Button, ErrorLabel, ExitLabel, FormContainer, RegistrationForm, Subtitle, SuccessMessage, Title } from "./WebinarRegisterCard.styles";

export const WebinarRegisterCard = () => {
    const [formData, setFormData] = useState({
        nombre: "",
        nombre_empresa: "",
        puesto: "",
        correo: "",
        telefono: ""
    });

    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isMessageSend, setIsMessageSend] = useState(false)

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone) => {
        const re = /^\+?[0-9]{10,15}$/;
        return re.test(String(phone));
    };

    const handleChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.nombre) {
            newErrors.nombre = "El nombre es obligatorio";
        }

        if (!formData.nombre_empresa) {
            newErrors.nombre_empresa = "El nombre de la empresa es obligatorio";
        }

        if (!formData.puesto) {
            newErrors.puesto = "El puesto es obligatorio";
        }

        if (!validateEmail(formData.correo)) {
            newErrors.correo = "Correo inválido";
        }

        if (!validatePhone(formData.telefono)) {
            newErrors.telefono = "Teléfono inválido";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        const isComplete = Object.values(formData).every(field => field !== "");
        setIsFormValid(isComplete && validateForm());
    }, [formData]);

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch("https://xi98869mj1.execute-api.us-west-2.amazonaws.com/v1/webinar", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Success:", data);
            setIsMessageSend(true);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <RegistrationForm>
            <FormContainer>
                <Title>
                    Regístrate al webinar
                </Title>
                <Subtitle>
                    Ingresa los siguientes datos para completar tu registro.
                </Subtitle>
            </FormContainer>
            <FieldLabel
                nombre="Nombre"
                placeholder="Ej. Juan Sanchez"
                value={formData.nombre}
                onChange={(e) => handleChange("nombre", e.target.value)}
            />
            {errors.nombre && <ErrorLabel>{errors.nombre}</ErrorLabel>}
            <FieldLabel
                propPadding="unset"
                nombre="Nombre de la empresa"
                placeholder="Ej. Dynamicore"
                value={formData.nombre_empresa}
                onChange={(e) => handleChange("nombre_empresa", e.target.value)}
            />
            {errors.nombre_empresa && <ErrorLabel>{errors.nombre_empresa}</ErrorLabel>}
            <FieldLabel
                propPadding="0px 0px 0.1px"
                nombre="Puesto"
                placeholder="Ej. CEO"
                value={formData.puesto}
                onChange={(e) => handleChange("puesto", e.target.value)}
            />
            {errors.puesto && <ErrorLabel>{errors.puesto}</ErrorLabel>}
            <FieldLabel
                propPadding="unset"
                nombre="Correo"
                placeholder="Ej. tucorreo@tumail.com"
                value={formData.correo}
                onChange={(e) => handleChange("correo", e.target.value)}
            />
            {errors.correo && <ErrorLabel>{errors.correo}</ErrorLabel>}
            <FieldLabel
                nombre="Teléfono"
                placeholder="Ej. 55 6696 4983"
                value={formData.telefono}
                onChange={(e) => handleChange("telefono", e.target.value)}
            />
            {errors.telefono && <ErrorLabel>{errors.telefono}</ErrorLabel>}
            {
                isMessageSend ?
                    <SuccessMessage> ¡Se ha completado el registro con éxito! </SuccessMessage> :
                    <Button disabled={!isFormValid} style={{ width: '100%', maxWidth: '100%', marginTop: 50 }} onClick={handleSubmit}>
                        Regístrate
                    </Button>
            }

        </RegistrationForm>
    );
};

import styled from 'styled-components';
import { GlobalFonts } from '../../globals';

export const PageContainer = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 5px;

  @media (max-width: 650px) {
    padding-top: 50px !important;
  }

  @media (min-width: 300px) and (max-width: 767px) {
    margin: 0;
    ~ #ContFooter {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 50px !important;
    margin: 0;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    padding: 50px 0 !important;
    margin: 0;
  }

  @media (min-width: 1280px) and (max-width: 1439px) {
    padding: 100px 0 !important;
    margin: 0 30px;
  }

  @media (min-width: 1440px) and (max-width: 1919px) {
    padding: 110px 0 !important;
    margin: 0 30px;
  }

  @media (min-width: 1920px) {
    padding: 130px 0 !important;
    margin: 0 30px;
  }
`

export const WebinarCard = styled.div`
  background-color: #312312;
  position: relative;
  mix-blend-mode: normal;
  margin: 0 !important;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  top: 0;
  padding-bottom: 56.25%;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;

  max-height: 150vh;
  display: flex;
  align-items: center;
  width: 85%;
  @media(min-width: 3000px){
    max-height: 10vh;
    padding-left: 30px;
    padding-right: 30px; 
    width: 95%;   
  }

  @media(min-width: 2900px) and (max-width: 3000px){
    max-height: 20vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 2700px) and (max-width: 2900px){
    max-height: 30vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 2500px) and (max-width: 2700px){
    max-height: 40vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 2300px) and (max-width: 2500px){
    max-height: 50vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 2050px) and (max-width: 2300px){
    max-height: 70vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1950px) and (max-width: 2050px){
    max-height: 77vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1850px) and (max-width: 1950px){
    max-height: 87vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1700px) and (max-width: 1850px){
    max-height: 77vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1600px) and (max-width: 1700px){
    max-height: 110vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1450px) and (max-width: 1600px){
    max-height: 130vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1350px) and (max-width: 1450px){
    max-height: 135vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1250px) and (max-width: 1350px){
    max-height: 160vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1024px) and (max-width: 1250px){
    max-height: 160vh;
    border-radius: 0px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  @media(min-width: 900px) and (max-width: 1024px){
    max-height: 300vh;
    border-radius: 0px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  @media(min-width: 640px) and (max-width: 900px){
    max-height: 240vh;
    border-radius: 0px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  @media(min-width: 0px) and (max-width: 640px){
  width: 91%;
  max-height: 400vh;
  border-radius: 0px;
  padding-left: 19px;
  padding-right: 19px;
  padding-bottom: 0%;
  }
  
  /* @media(min-width: 1250px) and (max-width: 1400px){
    max-height: 135vh;
  }

  @media(min-width: 1100px) and (max-width: 1250px){
    max-height: 145vh;
  }

  @media(min-width: 900px) and (max-width: 1100px){
    max-height: 155vh;
  }

  @media(min-width: 768) and (max-width: 900px){
    max-height: 155vh;
  } */

  &::before {
    content: "";
    display: block;
  }
`

export const Hero = styled.main`
  
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  gap: 50px;
  @media(max-width: 1024px){
    flex-direction: column;
  }  
  @media(min-width: 640px) and (max-width: 1250px){
  margin: 50px 30px;
  } 
`

export const ExitSide = styled.section`
  width: 50%; 

  @media(min-width: 1024px) and (max-width: 1536px){
    width: 50%;
  } 

  @media(max-width: 1024px){
    flex-direction: column;
    width: 95%; 
  }
  `

export const RegisterSide = styled.section`
  width: 40%;

  @media(min-width: 1024px) and (max-width: 1536px){
  width: 45%;
  } 
  
  @media(max-width: 1024px){
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    margin-left: auto;
  }
`

export const LabelContainer = styled.div`
  position: relative;
  border-radius: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  width: min-content;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;
    padding: 1px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

export const Label = styled.span`
  color: var(--white, #FFF);
  font-family: ${GlobalFonts.fontGilroySemiBold};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.815px;
`

export const Title = styled.h1`
  color: #FFF;
  font-family: ${GlobalFonts.fontBold};
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  margin-bottom: -8px;

  @media(max-width: 1024px){
    font-size: 30px;
    line-height: 50px;
    font-family: ${GlobalFonts.fontMedium};
  }
`

export const Paragraph = styled.p`
  color: #FFF;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;

  @media(max-width: 768px){    
    font-size: 18px;
    line-height: 25px;
    font-family: ${GlobalFonts.fontLight};
  }

  @media(min-width: 768px) and (max-width: 1024px){
    font-size: 18px;
    line-height: 30px;    
  }
`

export const Strong = styled.strong`
  font-family: ${GlobalFonts.fontBold};
  font-size: 20px;
  font-weight: 400;

  @media(max-width: 768px){
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  }

  @media(min-width: 768px) and (max-width: 1024px){
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }
`

export const Subtitle = styled.h4`
  color: #FFF;
  font-family: ${GlobalFonts.fontGilroySemiBold};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 0px;
  @media(max-width: 1024px){
    font-size: 24px;
  }
`

export const DatesSection = styled.div`
  display: flex;
  align-items: center;
`
export const DatesSubsection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: -5px;
  margin-bottom: -5px;
`

export const SpeakerSection = styled.section`
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
export const SpeakerContent = styled.div`
  display: flex;
  width: 50%;
  -webkit-box-pack: end;
  justify-content: center;
  @media(max-width: 1024px){
  width: 100%;
  max-width: 580px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  }
    @media(max-width: 485px){
    display: flex;
    
    align-items: center;
    gap: 20px;
  }
`

export const SpeakerCard = styled.div`
  position: relative;
  width: 210px;
  height: 236px;
  border-radius: 18px;
  overflow: hidden;
  margin-right: 20px;
  cursor: pointer;
  @media(max-width: 1024px){
    margin-right: 0px;
  }

  @media(min-width: 0px) and (max-width: 475px){
    width: 182.56px;
    height: 236px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 18px;
    padding: 1px;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  z-index: 0;
`

export const SpeakerCardDescription = styled.div`
  position: absolute;
  z-index: 50;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 95.5px;
  border: 0.299px solid #98F9FF;
  background: lightgray 0% 0% / 32.6803982257843px 32.6803982257843px repeat, radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 77.08%, rgba(255, 255, 255, 0.00) 100%);
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(13.382692337036133px);
  padding-left: 10px;
  display: flex;
  border: none;
`

export const SpeakerCardDescriptionParagraph = styled.p`
  color: #FFF;
  font-family: ${GlobalFonts.fontBold};
  font-size: 16.276px;
  font-style: normal;
  font-weight: 400;
  line-height: 113.4%;

  @media(max-width: 1024px){
    font-size: 14px;
  }
`

export const SpeakerCardDescriptionSmall = styled.p`
  color: #FFF;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 12.207px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.4%;

  @media(max-width: 1024px){
    font-size: 10px;
  }
`

export const SpeakerCardDescriptionLeft = styled.div`
  width: 80%;
`

export const SpeakerCardDescriptionRight = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

export const LinkedInImage = styled.img`
  width: 24.41px;
  height: 24.41px;
`

export const CounterSection = styled.section`
  width: 108%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
   @media (min-width: 640px) and (max-width: 1280px){
    align-items: center;
  }
`

export const CounterSectionTitle = styled.h1`
  color: #FFF;
  font-family: ${GlobalFonts.fontMedium};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.905px;
`

export const CounterGroup = styled.div`
  display: flex;

  @media(min-width: 0px) and (max-width: 640px){
    width: 100%;
    justify-content: space-around;
  } 

`

export const CounterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  

  @media(min-width: 0px) and (max-width: 640px){
    margin-bottom: 15px;
  } 

  @media (min-width: 640px) {
    margin-right: 40px;
  }
`

export const CounterSquad = styled.div`
  display: flex;
  width: 105px;
  height: 105px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #FFF;
  font-family: ${GlobalFonts.fontBold};
  font-size: 66.286px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media(min-width: 0px) and (max-width: 640px){
    width: 75px;
    height: 75px;
    font-size: 49px;
  } 
`;

export const CounterLabel = styled.div`
  color: #FFF;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;

  @media(min-width: 0px) and (max-width: 640px){
    font-size: 18.034px;
    font-style: normal;
    font-weight: 400;
    margin-top: 8px;
  } 
`

export const LearningSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4FAFF;
  border-radius: 40px;
  border: 3px solid #FFF;
  padding-top: 40px;
  margin-top: 100px;
  width: 95%;
  align-self: center;
  @media(min-width: 0px) and (max-width: 640px) {
  border-radius: 40px 40px 0px 0px;
  width: 98vw;
  }
`
export const LearningLabel = styled.div`
  border-radius: 40px;
  border: 1px solid var(--gradient, #C64DDA);
  background: var(--gradient, linear-gradient(90deg, #C64DDA 0%, #FF6F31 100%));
  font-family: ${GlobalFonts.fontGilroySemiBold};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.815px;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: min-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const LearningTitle = styled.h2`
  color: #1E1E1E;
  text-align: center;
  font-family: ${GlobalFonts.fontBold};
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  @media(max-width: 1024px){
  font-size: 35.198px;
  }
`

export const StepsSection = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 10px;
  
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const StepItem = styled.div`
  width: 100%;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 300px;
  }
`

export const StepCircle = styled.div`
  width: 75px;
  height: 75px;
  min-width: 75px;
  min-height: 75px;
  border-radius: 999px;
  background-color: #CEE1F8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${GlobalFonts.fontBold};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 640px) {
    width: 65px;
    height: 65px;
    min-width: 65px;
    min-height: 65px;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
  }
`

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
`

export const StepDescription = styled.h3`
  color: #1E1E1E;
  text-align: center;
  font-family: ${GlobalFonts.fontBold};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

`

export const Striped = styled.div`
  width: 100%;
  height: 2px;
  background: repeating-linear-gradient(
    90deg, 
    transparent, 
    transparent 5px, 
    #CEE1F8 5px, 
    #CEE1F8 14px
  );

  @media (max-width: 640px) {
    display: none;
  }
`;

export const Space = styled.div`
    width: 100%;
    height: 2px;
    color: transparent;
   
    @media (max-width: 640px) {
    display: none;
  }
`

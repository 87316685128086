import { useMemo } from "react";
import { FieldInputs, FieldLabelsContainer, Input, InputContainers, Nombre } from "./FieldLabel.styles"

export const FieldLabel = ({
    propPadding,
    nombre,
    placeholder,
    propWidth,
    onChange
  }) => {

    const fieldLabelsStyle = useMemo(() => {
        return {
          padding: propPadding,
        };
      }, [propPadding]);
    
      const inputStyle = useMemo(() => {
        return {
          width: propWidth,
        };
      }, [propWidth]);
    

    return (
        <FieldLabelsContainer style={fieldLabelsStyle}>
            <Nombre>{nombre}</Nombre>
            <FieldInputs>
                <InputContainers>
                    <Input
                        placeholder={placeholder}
                        type="text"
                        style={inputStyle}
                        onChange={onChange}
                    />
                </InputContainers>
            </FieldInputs>
        </FieldLabelsContainer>
    )
}

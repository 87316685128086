import React from 'react';
import {ContMenu} from './Menu.styles';
import ItemMenu from '../ItemMenu';

const Menu = () => {

  return (
    <ContMenu>
      <ItemMenu text='Home' linkTo='' type={'/'}/>
      <ItemMenu text='Nosotros' linkTo='nosotros' type={'/'}/>
      <ItemMenu text='Beneficios' linkTo='beneficios' type={'/'}/>
      <ItemMenu text='Servicios' linkTo='servicios' type={'#'} showDropdown={true}/>
      <ItemMenu text='Casos de uso' linkTo='caso-de-uso-credito' type={'/'}/>
      {/*<ItemMenu text='Marketplace' linkTo='marketplace' type={'/'}/>*/}
      <ItemMenu text='Blog' linkTo='https://blog.dynamicore.io/' type={'@'}/>
      <ItemMenu text='Webinar' linkTo='webinar' type={'/'} />
    </ContMenu>
  );
};

export default Menu;

export default {
  'Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Soluciones financieras, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera' : 
  'Core Banking, Fintech, Digital Onboarding, Credit Approval, Credit Origination, Collection Management, Credit Analysis, Financial Solutions, Process Automation, Digital Processes, Financial Product, Financial Services, Process Digitization, Financial Software, Disbursements, Automated Reporting, Financial Sector, AML, Antimoney laudry, API, Digital Financial Services, Financial Platform, Credit Card Issuance, Financial Transformation',
  'Descubre nuestra plataforma de core bancario digital diseñada para optimizar los procesos, automatizar operaciones y digitalizar productos financieros.' : 
  'Discover our digital core banking platform designed to optimize processes, automate operations and digitize financial products.',
  'Core Financiero, Core Bancario, Plataforma para sofom, Plataforma para sofipo, Software financiero, Tecnológica financiera, Dynamicore' :
  'Core Banking, Platform for Sofom, Platform for Sofipo, Financial Software, Financial Technology, Dynamicore, Financial Transformation, Fintech Software',
  'Somos líderes en el desarrollo de soluciones digitales para entidades financieras. Descubre quiénes somos y cómo podemos ayudar a tu institución a prosperar en la era digital.' : 'We are leaders in the development of digital solutions for financial institutions. Discover who we are and how we can help your institution thrive in the digital era.',
  'Explore los beneficios de nuestra plataforma, desde la simplificación del onboarding hasta la mejora de la gestión de créditos y la eficiencia en la cobranza. Optimiza tus operaciones.' :
  'Explore the benefits of our platform, from simplified onboarding to improved credit management and collection efficiency. Optimize your operations.',
  'Descubre cómo nuestra solución de onboarding digital agiliza el proceso de incorporación de clientes, mejorando la experiencia y la seguridad.' :
  'Discover how our digital onboarding solution streamlines the customer onboarding process, improving the experience and security.',
  'Nuestra herramienta de análisis de crédito te brinda información precisa para decisiones financieras acertadas. Optimiza la calidad de tus productos financieros y reduce riesgos' : 
  'Our credit analysis tool provides you with accurate information for sound financial decisions. Optimize the quality of your financial products and reduce risks.',
  'Maximiza la eficiencia de tus recursos con nuestra plataforma de dispersión de pagos. Simplifica los pagos a terceros y optimiza la gestión de transacciones.':
  'Maximize the efficiency of your resources with our payment dispersion platform. Simplify third-party payments and optimize transaction management.',
  'Descubre cómo un score alternativo optimiza la evaluación de riesgo crediticio y te brinda nuevas oportunidades financieras.': 
  'Discover how an alternative score optimizes credit risk assessment and provides you with new financial opportunities.',
  'En este Webinar descubrirás cómo un core financiero moderno puede ser la clave del éxito para tu entidad financiera. Además aprenderás cómo transformar tu operación, mejorar la eficiencia y ofrecer servicios innovadores.':
  'In this Webinar you will discover how a modern financial core can be the key to success for your financial institution. You will also learn how to transform your operation, improve efficiency and offer innovative services.'
};

import {
    CounterGroup,
    CounterSquad,
    CounterSection,
    CounterSectionTitle,
    DatesSection,
    DatesSubsection,
    ExitSide,
    Hero,
    Label,
    LabelContainer,
    LinkedInImage,
    PageContainer,
    Paragraph,
    RegisterSide,
    SpeakerCard,
    SpeakerCardDescription,
    SpeakerCardDescriptionLeft,
    SpeakerCardDescriptionParagraph,
    SpeakerCardDescriptionRight,
    SpeakerCardDescriptionSmall,
    SpeakerSection,
    Subtitle,
    Title,
    WebinarCard,
    CounterItem,
    CounterLabel,
    LearningSection,
    LearningLabel,
    LearningTitle,
    StepsSection,
    StepItem,
    StepCircle,
    Striped,
    StepHeader,
    Space,
    StepDescription,
    Strong,
    SpeakerContent
} from "./WebinarPage.styles";

import {
    CalendarTodayOutlined as CalendarTodayIcon
} from '@mui/icons-material';

import BackgroundImage from '../../assets/images/webinar/webinar-background.png';
import LinkedInLogo from '../../assets/images/LinkedIn-white.svg'

import Profile1 from '../../assets/images/webinar/OswaldoImage.png'
import Profile2 from '../../assets/images/webinar/IlseImage.png'
import WebinarRegisterCard from "../../components/WebinarRegisterCard";
import { Button } from "../../components/WebinarRegisterCard/WebinarRegisterCard.styles";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const speakers = [
    {
        name: "Oswaldo Didier",
        lastName: "Lopez Garcia",
        description: "Chief Executive Officer",
        linkedIn: "https://www.linkedin.com/in/waldix/",
        image: Profile1
    },
    {
        name: "Ilse",
        lastName: "Balbuena",
        description: "Key Account Manager",
        linkedIn: "https://www.linkedin.com/in/ilse-b-b11876132?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACCFJb0B8U-xk6LeN0Rw89ILhNvQc2xvEeY&lipi=urn%3Ali%3Apage%3Acompanies_company_people_index%3Bcb386a53-2e69-43b9-9527-3dba24c17f1c",
        image: Profile2
    }
]
export const WebinarPage = () => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const finalDate = new Date('2024-10-03T18:00:00').getTime();

        const updateCounter = () => {
            const now = new Date().getTime();
            const distance = finalDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimeLeft({ days, hours, minutes, seconds });

            if (distance < 0) {
                clearInterval(interval);
            }
        };

        const interval = setInterval(updateCounter, 1000);
        updateCounter();

        return () => clearInterval(interval);
    }, []);

    const scrollToRegister = () => {
        const element = document.getElementById('register');
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 100;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <PageContainer>
            <WebinarCard backgroundImage={BackgroundImage}>
                <Hero>
                    <ExitSide>
                        <LabelContainer>
                            <Label>Webinar</Label>
                        </LabelContainer>
                        <Title>
                            El éxito de las financieras: Core Financiero moderno
                        </Title>
                        <Paragraph>
                            En este Webinar descubrirás cómo un <Strong>core financiero moderno </Strong>
                            puede ser la clave del éxito para tu entidad financiera.
                            Además aprenderás cómo <Strong>transformar tu operación, mejorar
                                la eficiencia y ofrecer servicios innovadores.</Strong>
                        </Paragraph>

                        <Subtitle>¿Cuándo?</Subtitle>
                        <DatesSection>
                            <CalendarTodayIcon style={{ width: 40, height: 40, color: '#FFF' }} />
                            <DatesSubsection>
                                <Paragraph>
                                    Fecha: <Strong>Jueves 3 de Octubre</Strong> <br />
                                    Hora: <Strong>18:00 Hrs</Strong>
                                </Paragraph>
                            </DatesSubsection>
                        </DatesSection>

                        <CounterSection>
                            <CounterSectionTitle>
                                Empieza en:
                            </CounterSectionTitle>
                            <CounterGroup>
                                <CounterItem>
                                    <CounterSquad>
                                        {timeLeft.days}
                                    </CounterSquad>
                                    <CounterLabel>
                                        Dias
                                    </CounterLabel>
                                </CounterItem>

                                <CounterItem>
                                    <CounterSquad>
                                        {timeLeft.hours}
                                    </CounterSquad>
                                    <CounterLabel>
                                        Horas
                                    </CounterLabel>
                                </CounterItem>

                                <CounterItem>
                                    <CounterSquad>
                                        {timeLeft.minutes}
                                    </CounterSquad>
                                    <CounterLabel>
                                        Minutos
                                    </CounterLabel>
                                </CounterItem>

                                <CounterItem>
                                    <CounterSquad>
                                        {timeLeft.seconds}
                                    </CounterSquad>
                                    <CounterLabel>
                                        Segundos
                                    </CounterLabel>
                                </CounterItem>
                            </CounterGroup>
                        </CounterSection>

                    </ExitSide>

                    <RegisterSide id="register">
                        <WebinarRegisterCard />
                    </RegisterSide>
                </Hero>
                <SpeakerSection>
                    <Subtitle>Speakers</Subtitle>
                    <SpeakerContent>
                        {speakers.map((data) => (
                            <Link key={data?.linkedIn} to={data?.linkedIn} target="_blank">
                                <SpeakerCard backgroundImage={data?.image}>
                                    <SpeakerCardDescription>
                                        <SpeakerCardDescriptionLeft>
                                            <SpeakerCardDescriptionParagraph>
                                                <strong>{data?.name}</strong> <br />
                                                <strong>{data?.lastName}</strong>
                                            </SpeakerCardDescriptionParagraph>
                                            <SpeakerCardDescriptionSmall>
                                                {data.description}
                                            </SpeakerCardDescriptionSmall>
                                        </SpeakerCardDescriptionLeft>
                                        <SpeakerCardDescriptionRight>
                                            <LinkedInImage src={LinkedInLogo} />
                                        </SpeakerCardDescriptionRight>
                                    </SpeakerCardDescription>
                                </SpeakerCard>
                            </Link>
                        ))}
                    </SpeakerContent>
                </SpeakerSection>

                <LearningSection>
                    <LearningLabel>
                        Temario
                    </LearningLabel>
                    <LearningTitle>
                        ¿Qué aprenderás?
                    </LearningTitle>


                    <StepsSection >
                        <StepItem>
                            <StepHeader>
                                <Space />
                                <StepCircle>
                                    1
                                </StepCircle>
                                <Striped />
                            </StepHeader>
                            <StepDescription>
                                ¿Core Financiero Moderno?
                            </StepDescription>
                        </StepItem>

                        <StepItem>
                            <StepHeader>
                                <Striped />
                                <StepCircle>
                                    2
                                </StepCircle>
                                <Striped />
                            </StepHeader>
                            <StepDescription>
                                Transformación digital
                            </StepDescription>
                        </StepItem>

                        <StepItem>
                            <StepHeader>
                                <Striped />
                                <StepCircle>
                                    3
                                </StepCircle>
                                <Striped />
                            </StepHeader>
                            <StepDescription>
                                El rol clave de un core financiero moderno.
                            </StepDescription>
                        </StepItem>

                        <StepItem>
                            <StepHeader>
                                <Striped />
                                <StepCircle>
                                    4
                                </StepCircle>
                                <Striped />
                            </StepHeader>
                            <StepDescription>
                                Personalización y Escalabilidad
                            </StepDescription>
                        </StepItem>

                        <StepItem>
                            <StepHeader>
                                <Striped />
                                <StepCircle>
                                    5
                                </StepCircle>
                                <Space />
                            </StepHeader>
                            <StepDescription>
                                Integración de servicios innovadores
                            </StepDescription>
                        </StepItem>
                    </StepsSection>
                    <Button onClick={scrollToRegister} style={{ marginBottom: 30, maxWidth: 500 }}>
                        Registrate ahora
                    </Button>
                </LearningSection>
            </WebinarCard>
        </PageContainer>
    );
};

import styled from "styled-components";
import {GlobalFonts} from '../../globals';

export const FieldLabelsContainer = styled.div`
  height: 82.3px;
  display: flex;
  flex-direction: column;
  gap:  5.4px;
  text-align: left;
  font-size:  16.2px;
  color: #282828;
  margin-bottom: 10px;
`;

export const Nombre = styled.b`
  color: #282828;
  font-family: ${GlobalFonts.fontGilroySemiBold};
  font-size: 16.171px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.44px;
  @media(min-width: 0px) and (max-width: 640px){
    font-size: 14.8px;
  } 
`;

export const FieldInputs = styled.div`
  height: 59.9px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  border-radius: 11px;
  border: 3px solid rgba(5, 130, 255, 0.07);;
  box-sizing: border-box;
  flex-direction: row;
`;

export const InputContainers = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  border-radius: 10.78px;
  background-color: '#FFF';
  border: 2px solid #98cdff;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 14px 17px 12px;
`;

export const Input = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  display: inline-block;
  font-family: ${GlobalFonts.fontGilroySemiBold};
  font-size:  16.2px;
  background-color: transparent;
  height: 23px;
  position: relative;
  line-height: 22.44px;
  font-weight: 700;
  color: #000;
  text-align: left;
  padding: 0;
`;

import styled from 'styled-components';
import {GlobalFonts} from '../../globals';

export const RegistrationForm = styled.div`
  margin: 0;
  max-width: 580px;
  border-radius: 21.56px;
  background-color: #FFF;
  flex-direction: column;
  padding: 43px;
  box-sizing: border-box;
  gap: 32.3px;
  z-index: 2;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);

  @media(min-width: 0px) and (max-width: 640px){
    width: 95%;
    padding: 15px;
  } 
`

export const FormContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.8px;;
  max-width: 100%;
  margin-bottom: 32px;
  @media(min-width: 0px) and (max-width: 640px){
    margin-bottom: 30px;
  } 
`

export const Title = styled.h1`
  color: #0C3A72;
  text-align: center;
  font-family: ${GlobalFonts.fontBold};
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; 
  letter-spacing: 0.4px;
  
  @media(min-width: 0px) and (max-width: 640px){
   font-size: 27.641px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   margin-bottom: -1px;
  }    
`

export const Subtitle = styled.div`
  align-self: stretch;
  height: 13px;
  position: relative;
  font-size:  18.3px;
  font-family: ${GlobalFonts.fontRegular};
  color: #1e1e1e;
  text-align: left;
  display: inline-block;

  @media(min-width: 0px) and (max-width: 640px){
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }  
`
export const Button = styled.button`
  display: flex;
  width: 100%;
  padding: 35px 32.342px;
  justify-content: center;
  align-items: center;
  gap: 10.781px;
  border-radius: 10.7px;
  background: #0B6BDD;
  border: none;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  font-family: ${GlobalFonts.fontOutfitSemiBold};
  font-size: 19.405px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.194px;
  
  @media(min-width: 0px) and (max-width: 1024px){
    width: 90%;
    padding: 20px;
    font-size: 13.409px;
    letter-spacing: 1.3px;
    max-width: 360px;
  } 

  transition: background 0.3s ease;
  
  &:hover {
    background: #084b9b;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const ErrorLabel = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 700;
  font-family: ${GlobalFonts.fontOutfitSemiBold};
`

export const ExitLabel = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 700;
  font-family: ${GlobalFonts.fontOutfitSemiBold};
`

export const SuccessMessage = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: ${GlobalFonts.fontOutfitSemiBold};
  color: #0C3A72;
  text-align: center;
  margin-top: 30px;
`;

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import {Index} from './views';
import {Nosotros} from './views/Nosotros';
import {Servicios} from './views/Servicios';
import {AnalisisCredito} from './views/AnalisisCredito';
import {Pld} from './views/Pld';
import {Dispersion} from './views/Dispersion';
import {Cobranza} from './views/Cobranza';
import {CardEmision} from './views/CardEmision';
import {LockPhone} from './views/LockPhone';
import {PayServices} from './views/PayServices';
import {CampaignNoti} from './views/CampaignNoti';
import {Scoring} from './views/Scoring';
import {Beneficios} from './views/Beneficios';
import {NotFound} from './views/NotFound';
import {Contact} from './views/Contact';
import {AvisoPrivacidad} from './views/AvisoPrivacidad';
import {TerminosCondiciones} from './views/TerminosCondiciones';
import {ThanksView} from './views/ThanksView';
import {SiteMap} from './views/SiteMap';
import {UseCases} from './views/UseCases';
import { Webinar } from './views/Webinar';

function App() {
  return (
    <Router>
      <Routes>
        {/* url in english */}
        {/*
        <Route path='/' element={ <Index />} />
        <Route path='/about' element={ <Nosotros />} />
        
        <Route path='/onboarding' element={ <Servicios />} />
        <Route path='/scoring' element={ <AnalisisCredito />} />
        <Route path='/pld' element={ <Pld />} />
        <Route path='/send-money' element={ <Dispersion />} />
        <Route path='/payments' element={ <Cobranza />} />
        <Route path='/card-issuing' element={ <CardEmision />} />
        <Route path='/device-lock' element={ <LockPhone />} />
        <Route path='/bill-pay' element={ <PayServices />} />
        <Route path='/notifications' element={ <CampaignNoti />} />
        <Route path='/alternative-score' element={ <Scoring />} />

        <Route path='/benefits' element={ <Beneficios />} />
        <Route path='/contact-us' element={ <Contact />} />

        <Route path='/sitemap' element={ <SiteMap />} />

        <Route path='/privacy-policy' element={ <AvisoPrivacidad />} />
        <Route path='/terms-of-use' element={ <TerminosCondiciones />} />
        <Route path='/thanks' element={ <ThanksView/> } />
        */}

        {/* url en español*/}
        <Route path='/' element={ <Index />} />
        <Route path='/nosotros' element={ <Nosotros />} />
        
        <Route path='/onboarding' element={ <Servicios />} />
        <Route path='/analisis-crediticio' element={ <AnalisisCredito />} />
        <Route path='/pld' element={ <Pld />} />
        <Route path='/dispersion-pagos' element={ <Dispersion />} />
        <Route path='/cobranza' element={ <Cobranza />} />
        <Route path='/emision-tarjetas' element={ <CardEmision />} />
        <Route path='/bloqueo-dispositivos' element={ <LockPhone />} />
        <Route path='/pago-servicios' element={ <PayServices />} />
        <Route path='/notificaciones' element={ <CampaignNoti />} />
        <Route path='/score-alternativo' element={ <Scoring />} />

        <Route path='/caso-de-uso-credito' element={ <UseCases />} />

        <Route path='/beneficios' element={ <Beneficios />} />
        <Route path='/contacto' element={ <Contact />} />

        <Route path='/sitemap' element={ <SiteMap />} />

        <Route path='/aviso-de-privacidad' element={ <AvisoPrivacidad />} />
        <Route path='/terminos-y-condiciones' element={ <TerminosCondiciones />} />
        <Route path='/gracias' element={ <ThanksView/> } />
        <Route path='*' element={ <NotFound />} />
        <Route path='/webinar' element={ <Webinar/> } />
      </Routes>
    </Router>
  );
}

export default App;
